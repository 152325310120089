<template>
  <section class="home-section">
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <Header />
      <!-- partial -->
      <div class="container-fluid page-body-wrapper bg-light">
        <!-- partial:partials/_sidebar.html -->

        <!-- partial -->
        <div class="main-panel" style="width: 100%">
          <!---content wrapper start--->

          <div class="content-wrapper">
            <div class="row">
              <div class="col-md-12 grid-margin">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-end flex-wrap">
                    <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-account-multiple icon-md mr-1"></i> 
                         <i class="mdi mdi-menu-right icon-sm mr-1 "></i>
                         <span class=" py-2 " >Nouveau client</span>
                       </p>
                    </div>
                                            <!--     <button @click="notification">tester notification </button> -->

                  </div>
                  <!-- <div class="d-flex justify-content-between align-items-end flex-wrap">
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 d-none d-md-block ">
                    <i class="mdi mdi-download text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-clock-outline text-muted"></i>
                  </button>
                  <button type="button" class="btn btn-light bg-white btn-icon mr-3 mt-2 mt-xl-0">
                    <i class="mdi mdi-plus text-muted"></i>
                  </button>
                </div> -->
                </div>
              </div>
            </div>
                                <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger px-3 shadow-sm border border-white"
                            role="alert"
                          >
                          <i
                        class="mdi mdi-close-circle-outline pl-1"
                        style="color: darkred; font-size:20px;"
                      ></i>
                           {{error}}
                          </div>
                        </div>
                         <div class="">
               <p 
                v-if="chargement"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                 <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>
            <div class="row">
              <form
                class="forms-sample"
                @submit.prevent="handlesubmit"
                style="width: 100%"
              >
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">Informations :</h4>

                      <!------------------>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Nom</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="nom"
                            v-model="t.nom"
                            required
                          />
                        </div>
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Prénom</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="prenom"
                            v-model="t.prenom"
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="exampleInputEmail1">Adresse</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          placeholder="adresse"
                          v-model="t.adresse"
                          required
                        ></textarea>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Ville</label>
                          <select
                            class="form-control form-select form-select-md"
                            aria-label="Default select example"
                            v-model="t.ville_id"
                          >
                            <option
                              v-for="item in villes"
                              :value="item.id"
                              :key="item.id"
                            >
                              {{ item.city }}
                            </option>
                          </select>
                        </div>

                       <!--  <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Pays</label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail2"
                            placeholder="pays"
                            v-model="t.pays"
                            required
                          />
                        </div> -->
                      </div>
                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Email</label>
                          <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail2"
                            autocomplete="off"
                            placeholder="ex : email@email.com"
                            v-model="t.mail_reception"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-lg-6">
                          <label for="exampleInputUsername1">Mobile</label>
                          <input
                            type="text"
                            v-on:keypress="isNumber(event)"
                            class="form-control"
                            id="exampleInputEmail2"
                            autocomplete="off"
                            placeholder="ex : 06xxxxxxxx"
                            :minlength="10"
                            :maxlength="10"
                            v-model="t.mobile"
                          />
                        </div>
                      </div>


                     <!--  <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger text-center"
                            role="alert"
                          >
                            {{ error }}
                          </div>
                        </div>
                      </div>
 -->
                      <div class="row my-4">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3">
                          <button
                            type="submit"
                            class="btn btn-success mr-2"
                            style="width: 100%"
                            v-if="!isLoading"
                          >
                            Valider
                          </button>
                          <div class="text-center py-1" v-else>
                            <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color1"
                      :size="size1"
                    ></pulse-loader>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <button class="btn btn-secondary" style="width: 100%"  @click="back">
                            Annuler
                          </button>
                        </div>
                      </div>

                      <!---------->
                    </div>
                  </div>
                </div>
                <!-------------------------------->
              </form>

              <!----------------------------------------->
            </div>
          </div>

          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <Footer />
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>
<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Swal from "sweetalert2";
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: {
    Header,
    Footer,
    PulseLoader
  },
  data() {
    return {
      color:'#0275d8',
      size:'5px',
      color1:'#5cb85c',
      size1:'10px',
      max: 2,
      text: "",
      is_actived: 0,
      nom: "",
      prenom: "",
      adresse: "",
      ville: "",
      pays: "",
      mobile: "",
      email: "",
      t: {},
      villes: [],
      isLoading:false,

      oldNum: 0,
      error: "",
      chargement : true,
    };
  },
  mounted() {
    this.auth = this.$store.getters.get_user;
    console.log("auth", this.auth);
    console.log("auth id", this.auth["userable_id"]);
  },
  created() {
    this.$store
      .dispatch("getItem", {
        //data to be sended
        nameRoute: "/api/getallcities",
      })
      .then((res) => {
        this.chargement = false;
        console.log(res.data);
        this.villes = res.data;
      })
      .catch((err) => {
        this.chargement = false;
        console.log(err);
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
        this.error = "Une erreur s'est produite lors du chargement des villes ! ";
      });
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    handlesubmit() {
      this.error = "";
      Swal.fire({
        title: "Attention!",
        text: "Etes vous sur de vouloir ajouter ce client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.add();
        }
      });
    },
    back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },
  notification(msg){
      this.$notify({
  group : "foo",
  type: 'success',
  title: 'DONE',
  text: msg
});
    },
    notificationError(msg){
      this.$notify({
  group : "foo",
  type: 'error',
  title: 'Error',
  text: msg
});
    },

    add() {
      this.t.nom = this.t.nom + " " + this.t.prenom;
      (this.t.vendeur_id = this.auth["userable_id"]),
        (this.t.is_actived = this.is_actived);
      console.log(this.t);
      this.isLoading = true;
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/ajoutClient",
          t: this.t,
        })
        .then((res) => {
          this.isLoading = false;
          this.notification("Client ajouté avec succés")

          console.log(res.data);
          this.$router.push("/client");
        })
        .catch((error) => {
          this.notificationError("Un problème est survenu lors de l'ajout de ce client")
          this.isLoading = false;
          /* console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers); */
         /*  if (error.response.data.errors.email)
            this.error = error.response.data.errors.email[0]; */
            if (!error.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
         this.error = "Une erreur s'est produite lors de l'ajout de ce client"
        });
    },
  },
};
</script>
<style scoped>
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i {
  color: #6c757d;
  font-size: 30px;
}
</style>
import { Script } from "vm";
