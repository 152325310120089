<template>
  <div id="app">
    <div class="sidebar" v-if="loggedIn">
      <div class="logo-details">
        <div
          class="logo_name"
          style="
            color: #f7d648 ;
            font-weight: bolder;
            font-family: 'Times new roman';
          "
        >
          <!--              <span v-if="utilisateur && utilisateur.nom"> {{utilisateur.nom}} </span>
              <span v-else-if="profile == 'admin'">Admin</span>
              <span v-else-if="profile == 'manager'" >Manager</span>
              <span v-else-if="profile == 'back office' || profile == 'sous back office'"> Office</span> -->
          <!-- <button @click="stop">stop</button> -->
          {{ utilisateur.nom }}
        </div>
        <i class="bx bx-menu" id="btn" @click="menu"></i>
      </div>
      <ul class="nav-list mt-5">
        <li>
          <a
            @click="changeMenuColor('dash')"
            v-bind:class="menuItem == 'dash' ? 'bg-lighter' : ''"
          >
            <keep-alive>
              <router-link to="/dashboard">
                <i class="bx bx-grid-alt"></i>
                <span class="links_name">Dashboard</span>
              </router-link>
            </keep-alive>
          </a>
          <span class="tooltip">Tableau de bord</span>
        </li>

        <li>
          <a
            @click="changeMenuColor('colis')"
            v-bind:class="menuItem == 'colis' ? 'bg-lighter' : ''"
          >
            <router-link to="/colis">
              <i class="bx bx-package"></i>
              <span class="links_name">Colis</span>
            </router-link>
          </a>
          <span class="tooltip">Colis</span>
        </li>

        <li>
          <a
            @click="changeMenuColor('cl')"
            v-bind:class="menuItem == 'cl' ? 'bg-lighter' : ''"
          >
            <router-link to="client">
              <i class="bx bx-group"></i>
              <span class="links_name">Clients</span>
            </router-link>
          </a>
          <span class="tooltip">Clients</span>
        </li>

        <li>
          <a
            @click="changeMenuColor('st')"
            v-bind:class="menuItem == 'st' ? 'bg-lighter' : ''"
          >
            <router-link to="settings">
            <i class="bx bx-cog"></i>
            <span class="links_name">Parametres</span>
            </router-link>
          </a>
          <span class="tooltip">Parametres</span>
        </li>

        <li class="profile">
          <a v-on:click="logout()">
            <i class="bx bx-log-out" id="log_out"></i>
            <span class="links_name">Customer Account</span>
          </a>
          <span class="tooltip">Se deconnecter</span>
        </li>
      </ul>
    </div>
    <notifications group="foo" position="bottom right" width="50%" class="" :duration="duration"  />
    <router-view />
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      duration:5000,
      token: null,
      id: null,
      login: null,
      user: null,
      permissions: [],
      c: "",
      myInterval: 0,
      activity: {},
    };
  },

  computed: {
    loggedIn() {
      return this.$store.getters.get_loggedIn;
    },
    utilisateur() {
      return this.$store.getters.get_userAcc;
    },
    menuItem() {
      //console.log("menu item",this.$store.getters.get_menuItem)
      return this.$store.getters.get_menuItem;
    },
    userlogged() {
      return this.$store.getters.get_user;
    },
  },
  mounted() {
    console.log("*****************menu",this.menuItem)
    this.myInterval = window.setInterval(() => {
      if (this.testDeconnexion() >= 8) this.logout();
    }, 1000);

    //this.menu();
    /*function menuBtnChange() {
 if(sidebar.classList.contains("open")){
   closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
 }else {
   closeBtn.classList.replace("bx-menu-alt-right","bx-menu");//replacing the iocns class
 }
}*/
  },
  methods: {
    changeMenuColor(m) {
      this.$store.commit("set_ItemMenu", m);
     // this.menu = this.menuItem;
    },
    menu() {
      let sidebar = document.querySelector(".sidebar");
      let closeBtn = document.querySelector("#btn");
      sidebar.classList.toggle("open");

      this.menuBtnChange(sidebar, closeBtn);
    },
    menuBtnChange(sidebar, closeBtn) {
      if (sidebar.classList.contains("open")) {
        closeBtn.classList.replace("bx-menu", "bx-menu-alt-right"); //replacing the iocns class
      } else {
        closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    },
    logout() {
      this.stop();
      console.log(this.userlogged.id);
      //
      this.addActivity(this.userlogged.id, "/api/logout");
      this.$store
        .dispatch("performLogoutAction", {
          nameRoute: "/api/logout",
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addActivity(id, link) {
      this.activity.user_id = id;
      this.activity.action = "logged out";
      this.activity.model = "User";
      this.activity.url = this.$store.getters.get_link + link;
      this.activity.user_agent = navigator.appName + " " + navigator.appVersion;
      this.activity.item_id = id;
      console.log("add activity");

      console.log("activity..........", this.activity);
      this.$store
        .dispatch("postItem", {
          //data to be sended
          nameRoute: "/api/addactivity",
          t: this.activity,
        })
        .then((res) => {
          console.log("yes");
          console.log(res.data);
        })
        .catch((error) => {
          console.log("no");
          console.log(error);
        });
    },
    stop() {
      //console.log("**************", this.profile)
      console.log("stoped");
      window.clearInterval(this.myInterval);
    },
    testDeconnexion() {
      var login_time = moment(this.$store.getters.get_loginTime);
      var now_time = moment();
      return now_time.diff(login_time, "h");
    },
  },
};
</script>

<style scoped>
/* Google Font Link */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bg-lighter {
  background: #f7d648  !important;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 78px;
  background: #303868;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
}
.sidebar.open {
  width: 250px;
}
.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}
.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}
.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
  margin: 30%;
}
.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}
.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}
.sidebar.open .logo-details #btn {
  text-align: right;
}
.sidebar i {
  color: #acabab;
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}
.sidebar .nav-list {
  margin-top: 9px;
  height: 100%;
}
.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}
.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #acabab;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}
.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}
.sidebar input {
  font-size: 15px;
  color: #acabab;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #1d1b31;
  color: #acabab;
}
.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #acabab;
}
.sidebar .bx-search:hover {
  background: rgb(235, 147, 147);
  color: #303868;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  /* // background: #303868; */
}
.sidebar li a:hover {
  background: #f7d648;
}
.bg-darkslate {
  background: #303868;
}
.active {
  background: darkred;
}
.sidebar li a .links_name {
  color: #acabab;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}
.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #303868;
}
.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
}
.sidebar.open li.profile {
  width: 250px;
}
.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}
.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}
.sidebar li.profile .job {
  font-size: 12px;
}
.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}
.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #303868;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>
