<template>
  <section class="home-section">
    <div class="container-scroller">
      <Header />
      <div class="container-fluid page-body-wrapper bg-light">
        <div class="main-panel" style="width: 100%">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-md-12 grid-margin">
                <div class="d-flex justify-content-between flex-wrap row">
                  <div class="d-flex align-items-end flex-wrap col-lg-8">
                    <div class="mt-3">
                     <p><i class="mdi mdi-cube icon-md mr-1" style="color: #6c757d; font-size: 30px;"></i>  </p>
                    </div>
                  </div>
                  <div
                    class="col-lg-4 d-flex justify-content-end align-items-end flex-wrap"
                  >
                    <button
                      type="button"
                      class="btn btn-primary mr-2 btn-icon-text"
                      v-on:click="searchShow()"
                    >
                      <i class="mdi mdi-filter-variant btn-icon-prepend"></i>
                      <span class="font-weight-bold">Filtrer</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="row m-2" v-if="reduire">
                <div class="col-lg-12 stretch-card">
                  <div class="card">
                    <div
                      class="card-body pb-0 bg-light"
                      style="background: #d9d9d9"
                    >
                      <form class="forms-sample">
                        <div class="row">
                          <div class="form-group col-xl-3">
                            <label for="exampleInputName1 col-xl-2"
                              >Statut</label
                            >
                            <div class="form-control btn-group p-0">
                              <button
                                type="button"
                                class="btn btn-outline-light text-secondary btn-lg btn-block"
                                data-toggle="dropdown"
                                id="selection"
                              >
                                Selectionner
                                <i class="mdi mdi-chevron-down float-right"></i>
                              </button>
                              <div
                                class="dropdown-menu p-3"
                                style="
                                  width: 100%;
                                  max-height: 390px;
                                  overflow-y: auto;
                                "
                                aria-required="true"
                              >
                                <div>
                                  <div>
                                    <table>
                                      <tr>
                                        <th>
                                          <div class="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              @click="selectAll"
                                              v-model="allSelected"
                                              @change="changer()"
                                            />
                                            <label
                                              class="m-2"
                                              for="flexCheckDefault"
                                            >
                                              Selectionner tout
                                            </label>
                                          </div>
                                        </th>
                                      </tr>
                                      <tr v-for="user in users" :key="user.id">
                                        <td>
                                          <div class="form-check">
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              v-model="userIds"
                                              @click="select"
                                              :value="user.id"
                                              @change="changer()"
                                            />
                                            <label
                                              class="m-2"
                                              for="flexCheckDefault"
                                            >
                                              {{ user.name }}
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                     <!--      <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-2"
                              >Vendeur</label
                            >
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='Vendeur'"
                              placeholder="Vendeur "
                              aria-label="Search"
                              v-model="shipment.v_nom"
                              v-on:search="chercher()"
                            />
                          </div> -->

                          <div class="form-group col-xl-3">
                            <label for="exampleInputName1 col-xl-2"
                              >Point relais</label
                            >
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='Point relais'"
                              placeholder="Point relais "
                              aria-label="Search"
                              v-model="shipment.r_nom"
                              v-on:search="chercher()"
                            />
                          </div>

                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-2"
                              >Client</label
                            >
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='Client'"
                              placeholder="Client "
                              aria-label="Search"
                              v-model="shipment.c_nom"
                              v-on:search="chercher()"
                            />
                            
                          </div>

                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-2"
                              >Point D'enlèvement</label
                            >
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='Point relais'"
                              placeholder="Centre de tri "
                              aria-label="Search"
                              v-model="r_nom"
                              v-on:search="chercher()"
                            />
                            
                          </div>
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-1"
                              >Numéro de suivi :
                            </label>
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='No de suivi'"
                              placeholder="No de suivi"
                              aria-label="Search"
                              v-model="shipment.n_suivi"
                              v-on:search="chercher()"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-1"
                              >Type d'expédition :
                            </label>

                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='expedition'"
                              placeholder="Expedition "
                              aria-label="Search"
                              v-model="shipment.type_exp"
                              v-on:search="chercher()"
                            />
                          </div>
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-1"
                              >Numéro d'expédition :
                            </label>
                            <input
                              class="form-control"
                              type="search"
                              onfocus="this.placeholder=''"
                              onblur="this.placeholder='No d'expédition'"
                              placeholder="No d'expédition"
                              aria-label="Search"
                              v-model="shipment.n_exp"
                              v-on:search="chercher()"
                            />
                          </div>
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1  col-xl-1"
                              >De :
                            </label>
                            <input
                              class="form-control"
                              type="date"
                              placeholder=""
                              v-model="shipment.date_enlv_from"
                              v-on:change="chercher()"
                            />
                          </div>
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-1">A : </label>
                            <input
                              class="form-control"
                              type="date"
                              placeholder=""
                              v-model="shipment.date_enlv_to"
                              v-on:change="chercher()"
                            />
                          </div>
                          <!--------------->
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-2"
                              >De :
                            </label>
                            <input
                              class="form-control"
                              type="date"
                              placeholder=""
                              v-model="shipment.date_exp_from"
                              v-on:change="chercher()"
                            />
                          </div>
                          <div class="form-group col-xl-2">
                            <label for="exampleInputName1 col-xl-2">A : </label>
                            <input
                              class="form-control"
                              type="date"
                              placeholder=""
                              v-model="shipment.date_exp_to"
                              v-on:change="chercher()"
                            />
                          </div>
                        </div>
                        <p class="card-title float-lg-right">
                          <a  class="link-danger"  @click="reset">RESET</a>
                         <!--  <button class="btn btn-white text-danger fw-bold" type="button" @click="reset">
                            RESET
                          </button> -->
                        <!--   <a
                            class=" text-capitalize"
                            
                            style="color: darkred !important"
                            ><u>Reset</u>
                          </a> -->
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          

            <div class="row">
              <div class="stretch-card col-lg-12 m-1 p-0">
                <div class="card p-0">
                  <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">
                      <button
                        type="button"
                        class="btn btn-success mr-2 btn-icon-text"
                        v-on:click="ajouterColis()"
                      >
                        <i class="mdi mdi-plus-circle btn-icon-prepend"></i>
                        Nouveau colis
                      </button>
                       <button type="button" class="btn btn-warning  mr-2 btn-icon-text" @click="importCsv" > 
                      <i class="mdi mdi-upload btn-icon-prepend"></i>
                      Importer colis
                      </button>
                    </div>
                            <div class="row m-2">
       
                       <p class="alert alert-danger text-center font-weight-bold" v-if="error" >{{error}}</p>

     </div>
                      <div class="d-flex justify-content-start p-2">
                   
                          <button
                          class="btn btn-light btn-outline-dark px-3 mx-4 text-center d-flex"
                          @click="edit()"
                          :disabled="isLoading"
                          v-if="selection.length != 0"
                          
                        >
                          <!--v-if="item.latest_log.statut='En cours de preparation'"-->
                          
                          <i class="mdi mdi-truck btn-icon-prepend mr-1"></i>
                          <span v-if="!isLoading" style="align-items: center;">En attente d'enlevement</span>
                             <span v-else >
                             <pulse-loader
                           
                      class="mx-5"
                      :loading="true"
                      :color="color1"
                      :size="size1"
                    ></pulse-loader>
                    </span>
                        </button>

                  </div>
                    
                 
                 <p v-if="count" class="card-title text-center m-2"> <span  class="text-danger">{{ count }}</span>  resultat(s)</p>
           
                                                     
                     <div class="">
                        
               <p 
                v-if="chargement"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                  <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>

                    <div class="row m-3">
                      <table class="table table-hover table-stripped">
                        <tbody>
                          <tr class="bg-white text-black" style="height: 50px">
                            <th scope="row" class="text-left">
                              <input
                                type="checkbox"
                                value="1"
                                id="flexCheckDefault"
                                v-model="select_all"
                                @click="selecting"
                                :disabled="!allShow"
                              />
                            </th>
                            <td class="text-center font-weight-bold">Numéro</td>
                            <td class="text-left font-weight-bold">
                              N° expédition
                            </td>
                            <td class="text-left font-weight-bold">Statut</td>
                            <td class="text-left font-weight-bold">
                              Date d'enlèvement
                            </td>
                            <td class="text-left font-weight-bold">
                              Point d'enlèvement
                            </td>
                           <!--  <td class="text-left font-weight-bold">
                              Montant de retour
                            </td> -->
                            <td class="text-left font-weight-bold">
                              Date d'expiration
                            </td>
                            <td class="text-left font-weight-bold">Client</td>
                            <td class="text-left font-weight-bold">
                              Livraison
                            </td>
                           <!--  <td class="text-left font-weight-bold">Vendeur</td> -->
                            <td class="text-left font-weight-bold">
                              Point relais
                            </td>
                            <td class="text-left font-weight-bold">Actions</td>
                          </tr>
                          <tr class="odd text-center" v-if="count == 0">
                            <td
                              valign="top"
                              colspan="13"
                              class="dataTables_empty text-center text-primary bg-light font-weight-bold"
                            >
                              No data available in table
                            </td>
                          </tr>

                          <tr
                            v-for="(item, index) in colis"
                            :key="item.id"
                            v-bind:index="index"
                            style="cursor: default"
                          >
                            <th scope="row" class="text-left">
                              <input
                                type="checkbox"
                                :value="item.id"
                                id="flexCheckDefault"
                                v-model="selection"
                                v-show="item.latest_log.statut == 'En cours de preparation'"
                              />
                            </th>
                            <td class="text-center">{{ item.id }}</td>
                            <td class="text-left">{{ item.n_expedition }}</td>
                            <td
                              class="text-left text-secondary font-weight-bold"
                            >
                              {{ item.latest_log.statut }}
                            </td>
                            <td
                              class="text-center"
                              v-if="item.date_enlevement_demandee"
                            >
                              {{ item.date_enlevement_demandee }}
                            </td>
                            <td class="text-center" v-else>---</td>
                            <td class="text-left" v-if="item.warehouse">
                              {{ item.warehouse.nom }}-{{
                                item.warehouse.ville.city
                              }}
                            </td>
                           <!--  <td class="text-center">
                              {{ item.montant_retour }}
                            </td> -->
                            <td class="text-center" v-if="item.date_expiration">
                              {{ item.date_expiration }}
                            </td>
                            <td class="text-center" v-else>
                              ---
                            </td>
                            <td class="text-left">{{ item.client.nom }}</td>
                            <td class="text-left">
                             <!--  <a
                                @click.stop="showMessageConfirmation4Edit(item)"
                                title="editer"
                                class="px-1"
                                style="cursor: pointer"
                                ><i
                                  class="mdi mdi-pencil icon-sm text-success"
                                ></i
                              ></a> -->
                              {{ item.type_expedition }}
                            </td>
                            <!-- <td class="text-center">{{ item.seller.nom }}</td> -->
                            <td class="text-center">{{ item.relay.nom }}</td>
                            <td class="text-left">
                              <a
                                @click.stop="openModel(item)"
                                title="details"
                                class=""
                                style="cursor: pointer"
                                ><i
                                  class="mdi mdi-mdi mdi-eye icon-sm"
                                  style="color: #7bb2dd"
                                ></i
                              ></a>
                              <!--  <a  title="historique   " class="ml-1"  @click="historique(item.id)" style="cursor: pointer;" > <i class="mdi mdi mdi-history icon-sm" style="color :  #A6517E;"></i> </a>-->
                              <a
                                title="telecharger le bon de commande "
                                class="ml-1"
                                @click="pdf(item.id)"
                                
                                style="cursor: pointer"
                              >
                                <i v-if="selected != item.id"
                                  class="mdi mdi-file-pdf-box icon-sm"
                                  style="color: red"
                                ></i>
                                <i v-else
                                  class="mdi mdi-check icon-sm"
                                  style="color: green"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div
                        class="d-flex flex-row justify-content-center mb-1 p-2"
                      >
                      
                      </div> -->
                    </div>

                    <div v-if="myModel">
                      <transition name="model">
                        <div class="modal-mask w-100">
                          <div class="modal-wrapper  w-100">
                            <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-xl" style=" overflow-y: initial !important">
                              <div class="modal-content bg-light " style="">
                                <div class="modal-header bg-light">
                                  
                                 <h5
                              class="modal-title w-100 text-center text-secondary"
                            >
                              Informations colis 
                            </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="close()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold"></i>
                            </button>
                                </div>
                                <div class="modal-body w-100 " style="max-height: 80vh; overflow-y: auto;">
                                        <div class="row w-100 " >
            
                <div class="col-lg-12 grid-margin stretch-card mb-0">
                  <div class="card border-0 bg-light">
                    <div class="card-body">

                      <!------------------>
                      <div class="row">
                       <div class="card bg-light border-0 col-lg-4">
                         <div class="card-title mb-0">
                           <i class="mdi mdi-package-variant text-center mr-2 " style="font-size : 20px; color:#FFC00D !important;"></i>
                         </div>
                         <div class="card-body border-0 ml-2 mt-0 py-1">
                           <div class="row">
                             <div class="col-md-4 fw-bold">
                               Ref 
                             </div>
                             <div class="col-md-8">
                               {{item.ref}}
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-md-4 fw-bold">
                               N° exp
                             </div>
                             <div class="col-md-8">
                               {{item.n_expedition}}
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-md-4 fw-bold">
                               Poids
                             </div>
                             <div class="col-md-8">
                               {{item.poids}}
                             </div>
                           </div>

                         </div>

                       </div>
                       <!-------->
                                   <div class="card bg-light border-0 col-lg-4 ">
                         <div class="card-title mb-0">
                           
                           <i class="mdi mdi-map-marker text-dark mr-2" style="font-size : 19px; color:#8031A7 !important;"></i>
                         </div>
                         <div class="card-body  ml-2 py-1">
                           <div class="row">
                             <div class="col-md-4 fw-bold">
                               Relais 
                             </div>
                             <div class="col-md-8">
                               {{item.relay.nom}} {{item.relay.ville.city}}
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-md-4 fw-bold">
                               Livré à
                             </div>
                             <div class="col-md-8">
                               {{item.type_expedition}}
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-lg-4 fw-bold">
                               Date 
                             </div>
                             <div class="col-lg-8" v-if="item.date_expiration">
                               {{format_date(item.date_expiration)}}
                             </div>
                             <div class="col-lg-8" v-else>
                               ---
                             </div>
                           </div>

                         </div>

                       </div>
                       <!----->
                                   <div class="card bg-light border-0 col-lg-4">
                         <div class="card-title mb-0">
                           <i class="mdi mdi mdi-cart-outline text-dark mr-2" style="font-size : 19px; color:#78BE21 !important;"></i>
                         </div>
                         <div class="card-body ml-2 py-1">
                           <div class="row">
                             <div class="col-md-4 fw-bold">
                               Nom 
                             </div>
                             <div class="col-md-8">
                               {{item.client.nom}}
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-md-4 fw-bold">
                               Valeur
                             </div>
                             <div class="col-md-8">
                               {{item.valeur}} DH
                             </div>
                           </div>
                            <div class="row">
                             <div class="col-md-4 fw-bold">
                               COD
                             </div>
                             <div class="col-md-8">
                               {{item.cod}} DH
                             </div>
                           </div>

                         </div>

                       </div>
                       
                     
                      </div>

                     

                     
                

                     <!--  <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger text-center"
                            role="alert"
                          >
                            {{ error }}
                          </div>
                        </div>
                      </div>
 -->
                    

                      <!---------->
                    </div>
                  </div>
                </div>
                <!-------------------------------->

              <!----------------------------------------->
            <!----------------->
                  <div class="col-lg-12 grid-margin stretch-card m-0 ">

                  <div class="card border-0 bg-light">
                    <div class="card-body w-100 " >
                      <h4 class="card-title my-1 w-100 py-2 text-white text-center" style="background : #6A889C !important;"><i class="mdi mdi-history  mr-2" style="font-size : 19px; color:white !important;"></i> HISTORIQUE</h4>

                      <!------------------>
                      <div class="row ">
                        <div class="col-lg-1"></div>
                       <div class=" col-lg-11">
         <div id="tracking-pre"></div>
         <div id="tracking w-100  ">
           <!--  <div class="text-center tracking-status-intransit">
               <p class="tracking-status text-tight">Historique<nav>
                 </nav></p>
            </div> -->
            <div class="tracking-list border-0" v-for="it in item.logs" :key="it.id">
               <div class="tracking-item py-2 " style="box-shadow: rgba(0, 0, 0, 0.45) 0 4px 2px -2px">
                  <div class="tracking-icon status-intransit bg-danger" v-if="item.statut == 'Perdu' || item.statut == 'Annule' || item.statut == 'Retourne'">
                    <!--  <svg class="svg-inline--fa fa-check fa-w-16 text-white" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                        <path fill="green" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                     </svg> -->
                      <i class="mdi mdi-close fw-bold text-white"></i> 
                  </div>
                   <div class="tracking-icon status-intransit bg-success" v-else>
                    <!--  <svg class="svg-inline--fa fa-check fa-w-16 text-white" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                        <path fill="green" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                     </svg> -->
                      <i class="mdi mdi-check fw-bold text-white"></i> 
                  </div>
                  <div class="tracking-date ">{{format_date1(it.date)}}<span>{{format_date2(it.date)}}</span></div>
                  <div class="tracking-content">{{it.statut}}
                    <span v-if='it.statut == "En cours de preparation" || it.statut == "En attente enlevement"'>{{item.seller.ville.city}}</span>
                     <span v-else-if="it.statut == 'Arrive au centre de tri' || it.statut == 'Retourne au centre de tri'  ">JIBLILI {{item.warehouse.ville.city}}</span>
                      <span v-else-if="it.statut == 'Disponible au point relais' || it.statut == 'Non recupere' ">{{item.relay.nom}} {{item.relay.ville.city}}</span>
                      <span v-else-if="it.statut == 'Refuse par le client' || it.statut == 'Livre' || it.statut == 'Reception retour client'">{{item.client.nom}} </span>
                      <span v-else-if="it.statut == 'Perdu' || it.statut == 'Annule' || it.statut == 'Retourne'"> </span>
                     <span v-else-if="it.driver">
                       {{it.driver.nom}}

                     </span>
                     <span v-else>
                       

                     </span>
                  </div>
               </div>
        
               
              
               
               
             
              
            <!--    <div class="tracking-item">
                  <div class="tracking-icon status-outfordelivery">
                     <svg class="" aria-hidden="true" data-prefix="" data-icon="" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                        <path fill="green" d="M624 352h-16V243.9c0-12.7-5.1-24.9-14.1-33.9L494 110.1c-9-9-21.2-14.1-33.9-14.1H416V48c0-26.5-21.5-48-48-48H112C85.5 0 64 21.5 64 48v48H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h272c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H40c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h208c4.4 0 8 3.6 8 8v16c0 4.4-3.6 8-8 8H64v128c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h48c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zM160 464c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm320 0c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm80-208H416V144h44.1l99.9 99.9V256z"></path>
                     </svg>
                  </div>
                  <div class="tracking-date">Jul 20, 2018<span>08:58 AM</span></div>
                  <div class="tracking-content">Shipment is out for despatch by KLHB023.<span>KUALA LUMPUR (LOGISTICS HUB), MALAYSIA, MALAYSIA</span></div>
               </div>
         -->
             
            
            </div>
         </div>
      </div>
                       <!-------->
                                  
                       <!----->
                                 
                       
                     
                      </div>

                     

                     
                

                     <!--  <div class="row" style="margin-top: 1%">
                        <div class="col-12">
                          <div
                            v-if="error"
                            class="alert alert-danger text-center"
                            role="alert"
                          >
                            {{ error }}
                          </div>
                        </div>
                      </div>
 -->
                    

                      <!---------->
                    </div>
                  </div>
                </div>
             
    
                <!-------------------------------->
          </div>
                   
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <!-------------------------------->
                 <div v-if="modelUpload">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-lg" style=" overflow-y: initial !important">
         <div class="modal-content bg-light " style="">
            <div class="modal-header bg-light">
                  <h5
                              class="modal-title w-100 text-center text-secondary"
                            >
                              Importation des colis 
                            </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="closeUpload()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold icon-sm"></i>
                            </button>
                     </div>
         <div class=" bg-light p-0">
         
        <div class="modal-body row  m-0 w-100 container d-flex justify-content-center">


<!-- Upload  -->
<div class="">
 <!--  <label for="formFile" class="form-label">Default file input example</label>
  <input class="form-control" type="file" id="formFile"> -->
</div>
<form id="file-upload-form" class="uploader bg-light  ">
 

  <label for="file-upload" id="file-drag" class="bg-light">
    <div id="start">
      <i class="fa fa-times-circle text-danger my-5" aria-hidden="true" v-if="showError" style="font-size:5em;"></i>
      <i class="fa fa-download my-5" aria-hidden="true" v-else></i>
   </div>
    <div class="row" >  
        <input class="form-control  my-2" placeholder="choisir" type="file" id="file" ref="file" @change="getFile" v-if="showBtn">
    </div>

   

     <div class="progress mt-2" v-if="showProgress"  >
    <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary border border-dark" role="progressbar"   v-bind:style="{width: getProgressBar + '%',}">  <p v-if="getProgressBar >=10">{{getProgressBar}} % </p></div>
    
    
    </div>
   

     <p class=" d-flex justify-content-center align-self-center flex-column " v-if="showMsg" >
                            <i class="mdi mdi-check icon-lg text-success fw-bold" ></i> 
                         <span class=" py-2 px-2 fw-bolder d-block" >Le téléchargement a été effectué avec succés!</span>
                       </p>
    
    <p class=" d-flex justify-content-center align-self-center " v-if="showEncours" >
                         <span class=" py-2 px-2 fw-bolder " >Vérification en cours ...</span>
                       </p>
    <p class=" d-flex justify-content-start align-self-center alert alert-danger border border-white shadow-sm" v-if="showError" >
                         <span class=" py-2 px-2 fw-bolder " >Le fichier n'a pas pu étre importé, Verifiez les erreurs suivantes</span>
                       </p>
    <table class="table table-danger" v-if="showError">
      
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Ligne</th>
      <th scope="col">Erreur</th>
    </tr>
  </thead>
  <tbody>
    <tr  v-for="(item, index) in InvalidShipments" :key="item.row">
      <th scope="row">{{index + 1}}</th>
      <td>{{item.row}}</td>
      <td class="text-left">{{item.errors[0]}}</td>
    </tr>
   
  </tbody>
</table>
  

<div class="d-flex justify-content-center my-3" v-if="showBtn" >
 <div class="row w-75 " >
 
   <div class="col-lg-12">
<!--         <ejs-combobox required id='combobox' :dataSource='depots' v-model:value="warehouseSelected" popupHeight="400px"   :fields='fields' placeholder='Selectionner un centre de tri'></ejs-combobox>    
 -->
 <div class="form-group">
                                <select
                                  class="form-control form-select form-select-md mb-3"
                                  aria-label="Default select example"
                                  v-model="depot_id"
                                  required
                                >
                                <option value="" class="text-secondary" disabled selected>Séléctionner un centre de tri</option>
                                  <option
                                    v-for="item in depots"
                                    :value="item.id"
                                    :key="item.id"
                                  >
                                    {{ item.nom }} -- {{ item.ville.city }}
                                  </option>
                                </select>
                              </div>
   </div>
   </div>

   
  </div>
    <div class="d-flex justify-content-center mt-2" v-if="showBtn">
  <button class="btn btn-success px-5 my-2" type="button" @click="importFile" :disabled="nofiles || !depot_id" >Importer le fichier</button>
  
</div>



  </label>
  
</form>
 <p class=" d-flex justify-content-center align-self-center flex-row" v-if="showMsg" >
                         <span class="mt-3"><button class=" py-2 px-3 fw-bolder btn btn-primary " @click="getMergePdf()" :disabled="isLoading">
                           <i class="mdi mdi-file-pdf-box btn-icon-prepend mr-1"></i><span v-if="!isLoading">Télécharger les bon de commandes</span>
                           <span v-else > Chargement en cours ...</span>
                           </button>
                           </span>
                         
  </p>




           
      
</div>
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>
          

          <Footer />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Swal from "sweetalert2";
import moment from "moment";
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: {
    // Menu,
    Header,
    Footer,
    PulseLoader,
    //Datepicker
  },
  data() {
    return {
      color:'#0275d8',
      size:'5px',
      color1:'#000000',
      size1:'5px',
      colis: null,
      item:{},
      chargement:false,
      allShow:true,
      modelUpload:false,
      ImportedShipments:[],
      showBtn:true,
      showProgress:false,
      showMsg:false,
      nofiles:true,
      showEncours:false,
      videoDownloadPercentage:0,
      sellerSelected:'',
      warehouseSelected:'',
      ex:null,
      showError:'',
      r_nom: "",
      c_nom: "",
      options2: [],
      type_exp: "",
      date_exp_to: "",
      date_exp_from: "",
      date_enlv_to: "",
      date_enlv_from: "",
      isLoading:false,
      n_exp: "",
      err2:true,
      count: "",
      shipment: {},
      item2: {},
      reduire: false,
      allSelected: false,
      select_all: false,
      myModel: false,
      t: {},
      selected:null,
      userIds: [],
      depots: null,
      depot_id: "",
      selection: [],
      auth: {},
      error:'',
      users: [
        { id: "En cours de preparation", name: "En cours de préparation" },
        { id: "En attente enlevement", name: "En attente d'enlevement" },
        { id: "Ramassage en cours", name: "Ramassage en cours" },
        { id: "Arrive au centre de tri", name: "Arrivé au centre de tri" },
        { id: "En cours de distribution", name: "En cours de distribution" },
        {
          id: "Disponible au point relais",
          name: "Disponible au point relais",
        },
        { id: "Livraison en cours", name: "Livraison en cours" },
        { id: "Refuse par le client", name: "Refusé par le client" },
        { id: "Livre", name: "Livré" },
        { id: "Reception retour client", name: "Réception retour client" },
        { id: "Non recupere", name: "Non récupéré" },
        {
          id: "Retour centre de tri en cours",
          name: "Retour centre de tri en cours",
        },
        { id: "Retourne au centre de tri", name: "Retourné au centre de tri" },
        {
          id: "Retour expediteur en cours",
          name: "Retour expediteur en cours",
        },
        { id: "Retourne", name: "Retourné" },
        { id: "Perdu", name: "Perdu" },
        { id: "Annule", name: "Annulé" },
      ],
    };
  },
  mounted() {
    this.auth = this.$store.getters.get_user;
    this.afficherDepots();
    console.log("auth", this.auth);
    console.log("auth id", this.auth["userable_id"]);
  },


  computed:{
    utilisateur() {
      return this.$store.getters.get_userAcc;
    },
    getProgressBar() {
      console.log(this.videoDownloadPercentage)
       return this.videoDownloadPercentage;
   },

  },
  methods: {
    close() {
      this.myModel = false;
      this.item = {};
    },
    afficherDepots(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getwarehouses',
      })
      .then( res => {
        this.isLoading = false;
        this.depots = res.data;
        //this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },
    fillArr2(res){
      console.log("for")
         for (let i in res) {
           let warehouse = {} ;
           warehouse.value = res[i].id;
           warehouse.text =  res[i].nom;
           console.log("*************warehouse")

           this.options2.push(warehouse);}
          console.log("*************", this.options2)

    },

       importFile(){
     this.showBtn=false;
     this.showProgress = true;
/*      console.mpg("eeeeee")
     const formData = new FormData();
    const imagefile = document.querySelector('#file');
    formData.append("file", this.ex);
      axios.post('https://bojibexp.jibliliexpress.ma/public/api/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }) */
      console.log("***************************** vp",this.getProgressBar)

      console.log("*******");
     const formData = new FormData();
     const config = {
        //Choose upload
    
       onUploadProgress: event => {
          /*  this.videoDownloadPercentage = Math.round(
               (event.loaded * 100) / event.total
          ); */
          const {loaded, total} = event;
           console.log("***************************** vp",this.getProgressBar)

          this.videoDownloadPercentage = Math.floor((loaded * 100) / total);
          
          //console.log(`${loaded}kb of ${total}kb | ${this.videoDownloadPercentage}%`);
          if(this.videoDownloadPercentage == 100) {this.showEncours = true;}
          
        },
    };
     
     formData.append('file', this.ex);
     formData.append('seller_id', this.utilisateur.id);
     formData.append('warehouse_id', this.depot_id);
          this.$store.dispatch('postFile', {//data to be sended
          nameRoute : '/api/import',
          formdata : formData,
          config : config
          })    
        .then(res => {
          console.log(res.data);
          if(res.data.success)
          //if(res ==  100 ) this.closeUpload();
          {
          console.log("yes");
          this.showMsg = true;
          this.showProgress = false;
          this.showEncours = false;
          this.notification();
          
          this.ImportedShipments = res.data.Shipments
          console.log("************1", this.ImportedShipments)
          //this.getMergePdf();
          }
          else
          {
            this.InvalidShipments = res.data;
            this.InvalidShipments.sort((t1,t2) => t1.row <= t2.row ? -1 : 1);
            console.log("errors");
            //this.exportExcel();
          this.showProgress = false;
          this.showEncours = false;
          this.showError = true;
          }
        })
        .catch(error => {

          this.notificationError();
          this.closeUpload();
              console.log(error)
           
        }) 
   
   },

   getFile(e){
    //  var files = e.target.files || e.dataTransfer.files;*
     this.ex = e.target.files[0];
     if(this.ex) this.nofiles = false;
         console.log(this.ex)


   /*  const reader = new FileReader();

    reader.readAsText(this.t.file);

    reader.onload = () => {
      console.log(reader.result);
      //this.t.file = reader.result; // contains the file content as a string
    };

    reader.onerror = () => {
    console.log(reader.error);
   }; */

  

   },
   getMergePdf(){
        this.isLoading = true;
        console.log("getMergePDF");
        console.log("**********",this.ImportedShipments)
        this.$store.dispatch('getFile', {//data to be sended
      nameRoute : '/api/mergepdfs-cam',
      type:"POST",
      ref:"",
      ids : this.ImportedShipments,
      name: "importedShipmenrs "+moment().format('YYYY-MM-DD'),//+this.sellerSelected+moment().format('MM/DD/YYYY'),
      ext:'.pdf'
            })
      .then( res => {
        //console.log(res.data);
        //console.log(res.data);
        //this.notification();
        console.log(res)
        this.isLoading = false;
        this.closeUpload();
       
       })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
        //this.notificationError()
      }) 

      },

    reset() {
      console.log(this.date_enlv_from)
      this.shipment = {};
      this.n_exp = "",
      this.r_nom = "",
      this.c_nom = "",
      this.c_prenom = "",
      this.v_nom = "",
      this.n_suivi = "",
      this.type_exp = "",
      this.date_exp_from = "",
      this.date_exp_to = "",
      this.date_enlv_from = "",
      this.date_enlv_to = "",
      this.cod = "";
      this.userIds = [];
      this.count = 0;
      this.colis = null;
      this.allSelected = false;
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    format_date1(value) {
      if (value) {
        return moment().format("MMM Do YY");  
      }
    },
    format_date2(value) {
      if (value) {
        return moment().format('LT');
      }
    },

    closeUpload(){
    this.modelUpload = false;
    this.showBtn = true;
    this.showProgress = false;
    this.showMsg = false;
    this.nofiles = true;
    this.showEncours = false;
    this.videoDownloadPercentage = 0;
    this.sellerSelected = '';
    this.warehouseSelected = '';
    this.ex = null;
    this.showError = false;

  },
    
    searchShow() {
      this.reduire = !this.reduire;
    },
    importCsv(){
      console.log(this.utilisateur)
     this.modelUpload = true;

   },
    changer() {
      this.error="";
      console.log(this.c_nom);
       if(this.userIds.length<17) document.getElementById("selection").innerHTML=this.userIds.length+" selectionné (s)";
       if(this.userIds.length==17) document.getElementById("selection").innerHTML="Tous les statuts";
       if(this.userIds.length==0) document.getElementById("selection").innerHTML="Selectionner";
              
      this.chercherColis();
    },
    selectAll() {
      this.userIds = [];
      if (!this.allSelected) {
        for (let i = 0; i < this.users.length; i++) {
          this.userIds.push(this.users[i]["id"]);
        }
      }
    },
    selecting() {
      this.selection = [];
      if (!this.select_all)
        for (let i in this.colis) this.selection.push(this.colis[i].id);
    },
    edit() {
      this.error = "";
      this.isLoading=true;
      this.showEdit = true;
      this.t.ids = this.selection;
      this.$store
        .dispatch("postItem", {
          nameRoute: "/api/editStatuts",
          t: this.t,
        })
        .then((res) => {
          this.notification("Statut modifié avec succés")
          console.log(res);
          this.isLoading=false;
          this.chercherColis();
        })
        .catch((err) => {
          this.isLoading=false;
          this.notificationError("Un problème est survenu lors de la modification du statut")
          console.log("error 1")
          console.log(err);
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
          this.error = "Une erreur s'est produite lors de la modification des statuts! ";
        });
    },
    openModel(item) {
      console.log("clicked");
      console.log(item);
      this.item = item;
      console.log(this.item)
      this.myModel = true;
    },
    select() {
      this.allSelected = false;
    },
    pdf(id) {
      this.selected = id;
      this.error = "";
      console.log("pdf");
      this.$store
        .dispatch("getPdf", {
          //data to be sended
          nameRoute: "/api/generate-pdf-cam/" + id,
          id: id,
        })
        .then((res) => {
          this.selected = null;
          console.log(res);
          this.notification();
        })
        .catch((err) => {
          this.selected = null;
          console.log("error 2")
          console.log(err);
          this.notificationError();
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
         this.error = "Une erreur s'est produite lors de téléchargement du bon de commande"
        });
    },
     notification(msg){
      this.$notify({
  group : "foo",
  type: 'success',
  title: 'DONE',
  text: msg
});
    },
    notificationError(msg){
      this.$notify({
  group : "foo",
  type: 'error',
  title: 'Error',
  text: msg
});
    },
    ajouterColis() {
      console.log("clicked!");
      this.$router.push("/addshipment");
    },
    chercher() {
      this.error="";
      console.log("yes");
      this.chercherColis();
    },
    chercherColis() {
      this.error="";
      this.chargement=true;
      console.log("chercher");
      this.shipment.items = this.userIds;
      this.$store
        .dispatch("postItem", {
          nameRoute: "/api/searchSeller/" + this.auth["userable_id"],
          t: this.shipment,
        })
        .then((res) => {
          console.log("yes")
          this.chargement=false;
          console.log("filtre", res.data);
          this.colis = res.data;
          this.count = res.data.length;
          this.check();
        })
        .catch((err) => {
          console.log("error 3")
          this.chargement=false;
          console.log(err);
          if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur"
         else
          this.error = "Une erreur s'est produite lors de la recherche des colis ! ";
        });
    },
    check(){
      console.log("**************checked")
      this.allShow = false;
      for (let i in this.colis) 
      {
        console.log("********",this.colis[i]["latest_log"]["statut"]);
        if (this.colis[i]["latest_log"]["statut"] == "En cours de preparation")  {this.allShow=true; break;}
      }

    },
    showMessageConfirmation4Edit(item) {
      Swal.fire({
        title: "Attention!",
        text: "Etes vous sur de vouloir changer le mode de livraison?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.changementLivraison(item);
        }
      });
    },
    changementLivraison(item) {
      console.log(item.id);
      this.isLoading = true;
      this.$store
        .dispatch("idItem", {
          //data to be sended
          nameRoute: "/api/editModeLivraison/" + item.id,
        })
        .then((res) => {
          console.log(res);
          this.chercherColis();
          // this.parm = item.id;
          // this.$router.push({ name: 'pdfCode', params: {id:item.id}})
        })
        .catch((err) => {
          console.log(err);
          // this.isLoading = false;
          
          this.error = "Une erreur s'est produite ! ";
        });
    },
    historique(id) {
      this.$router.push({ name: "historique", params: { id: id } });
    },
    
  },
};
</script>
<style scoped lang="scss">
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: gray;
}
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.input-search {
  border: white;
  background-color: transparent;
  font-family: ROBOTO;
  color: black;
  font-weight: normal;
  box-shadow: 0px 15px 10px -15px #111;
}

.input-search::placeholder {
  font-family: ROBOTO;
  color: black;
  font-weight: bolder;
  font-size: 14px;
}
input::placeholder {
  font-family: ROBOTO;
  font-style: normal;
  color: gray;
  font-size: 14px;
}
.input-search:-webkit-autofill:active {
  font-family: ROBOTO;
  color: darkslategray;
  font-weight: normal;
  font-size: 14px;
  background: darkslategray;
}

input[type="checkbox"]:checked {
  /*CSS after CHECKED*/
  color: #ffd369;
}

.dropdown-toggle:hover,
input[type="search"].input-search:hover,
.dropdown-toggle:active {
  background: #d7dbdc;
  font-weight: normal;
}
a {
  cursor: pointer;
  color: darkred !important;
}
a:hover {
  color: rgb(116, 114, 114) !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content{
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}


/* showing modal tracking */
.tracking-detail {
 padding:3rem 0
}
#tracking {
 margin-bottom:1rem
}
[class*=tracking-status-] p {
 margin:0;
 font-size:1.1rem;
 color:#fff;
 text-transform:uppercase;
 text-align:center
}
[class*=tracking-status-] {
 padding:1.6rem 0
}
.tracking-status-intransit {
 background-color:#65aee0
}
.tracking-status-outfordelivery {
 background-color:#f5a551
}
.tracking-status-deliveryoffice {
 background-color:#f7dc6f
}
.tracking-status-delivered {
 background-color:#4cbb87
}
.tracking-status-attemptfail {
 background-color:#b789c7
}
.tracking-status-error,.tracking-status-exception {
 background-color:#d26759
}
.tracking-status-expired {
 background-color:#616e7d
}
.tracking-status-pending {
 background-color:#ccc
}
.tracking-status-inforeceived {
 background-color:#214977
}
.tracking-list {
 border:1px solid #e5e5e5
}
.tracking-item {
 border-left:1px solid #e5e5e5;
 position:relative;
 padding:2rem 1.5rem .5rem 2.5rem;
 font-size:.9rem;
 margin-left:3rem;
 min-height:5rem
}
.tracking-item:last-child {
 padding-bottom:4rem
}
.tracking-item .tracking-date {
 margin-bottom:.5rem
}
.tracking-item .tracking-date span {
 color:#888;
 font-size:85%;
 padding-left:.4rem
}
.tracking-item .tracking-content {
 padding:.5rem .8rem;
 background-color:#f4f4f4;
 border-radius:.5rem
}
.tracking-item .tracking-content span {
 display:block;
 color:#888;
 font-size:85%
}
.tracking-item .tracking-icon {
 line-height:2.6rem;
 position:absolute;
 left:-1.3rem;
 width:2.6rem;
 height:2.6rem;
 text-align:center;
 border-radius:100%;
 font-size:1.1rem;
 background-color:#fff;
 color:#fff
}
.tracking-item .tracking-icon.status-sponsored {
 background-color:#f68
}
.tracking-item .tracking-icon.status-delivered {
 background-color:#4cbb87
}
.tracking-item .tracking-icon.status-outfordelivery {
 background-color:#f5a551
}
.tracking-item .tracking-icon.status-deliveryoffice {
 background-color:#f7dc6f
}
.tracking-item .tracking-icon.status-attemptfail {
 background-color:#b789c7
}
.tracking-item .tracking-icon.status-exception {
 background-color:#d26759
}
.tracking-item .tracking-icon.status-inforeceived {
 background-color:#214977
}
.tracking-item .tracking-icon.status-intransit {
 color:#e5e5e5;
 border:1px solid #e5e5e5;
 font-size:.6rem
}
@media(min-width:992px) {
 .tracking-item {
  margin-left:10rem
 }
 .tracking-item .tracking-date {
  position:absolute;
  left:-10rem;
  width:7.5rem;
  text-align:right
 }
 .tracking-item .tracking-date span {
  display:block
 }
 .tracking-item .tracking-content {
  padding:0;
  background-color:transparent
 }
}

.uploader {
  display: block;
  clear: both;
  width: 100%;

  
  }

  .uploader  label {
    float: left;
    clear: both;
    width: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    border-radius: 7px;
    transition: all .2s ease;
    user-select: none;


  }
  .uploader  label.label{
    padding: 0% important;
    background: red;
  }

  .uploader  #start {
    float: left;
    clear: both;
    width: 100%;}

   .uploader  #start i.fa {
      font-size: 50px;
      margin-bottom: 1rem;
      transition: all .2s ease-in-out;
    }
  
  

  
  
  

  



 

  



</style>
