
<script>
import { Line } from "vue-chartjs";
 import moment from 'moment';
 

export default {
  extends: Line,

  data() {
    return {
      count: [],
      count2: [],
      date:[],
      t:{},
      chartData: {
                  labels: [],
                  datasets: [
                    {
                      label: "Livré",
                      data: [],
                      fill: false,
                      borderColor: "green",
                      backgroundColor: "green",
                      borderWidth: 1,
                    },
                    {
                      label: "Retourné",
                      data: [],
                      fill: false,
                      borderColor: "darkred",
                      backgroundColor: "darkred",
                      borderWidth: 1,
                    },
                  ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  props: {
    entred_data:String ,
    tl : String,
  },

  computed: {
    user() {
      return this.$store.getters.get_userAcc;
    },
  },
   changeMsg() {
          this.$emit("changeMsg", this.tl);
          console.log('message emit from child component')
        },
  beforeMount() {
    if(this.entred_data) this.t.year = this.entred_data;
    else this.t.year = moment().format('YYYY');

    this.$store
      .dispatch("postItem", {
        //data to be sended
        nameRoute: "/api/chartSeller/" + this.user.id,
        t: this.t,
      })
      .then((res) => {
        this.r = res.data;
        console.log("************************resultat",this.r);
        this.getYAxis(res.data);
      })
      .catch((err) => {
        // this.isLoading = false;
        console.log(err)
        this.error = "Une erreur s'est produite ! ";
      });
  },
  mounted() {
   // if(this.show = true)     this.renderChart(this.chartData, this.options);

  },
  methods:{
    getYAxis(res){
    let Xaxis = ["Janv",
                    "Févr",
                    "Mars",
                    "Avr",
                    "Mai",
                    "Juin",
                    "Juill",
                    "Aout",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Déc",]
    
        for(var i in res)
    {
      this.count.push(res[i].livre);
      this.count2.push(res[i].retourne)
    
    } 
          this.date = Xaxis.splice(res[0].month-1, Xaxis.length-1);

        console.log("count", this.date)
                this.chartData.datasets[0].data = this.count;
               // this.chartData.datasets[0].label = this.tl;
                this.chartData.datasets[1].data = this.count2;
                this.chartData.labels = this.date;
                this.renderChart(this.chartData, this.options);
                



    }
  }
};
</script>
