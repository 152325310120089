

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                   <div class="mt-3">
                     <p><i class="mdi mdi-account-multiple icon-md mr-1" style="color: #6c757d; font-size: 30px;"></i>  </p>
                    </div>
      


                </div>
                <div class="col-lg-4 d-flex justify-content-between align-items-end flex-wrap">
                    <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
              <div class="input-group-prepend bg-light" style="height : 40px;">
                <span class="input-group-text bg-light" id="search">
                  <i class="mdi mdi-magnify text-dark"></i>
                </span>
              </div>
<input type="search" class="form-control bg-light " v-model="searchQuery"  @focus="reset()"  placeholder="Search now" aria-label="search" aria-describedby="search" style="height : 40px; border : none; ">
                       </div>
                  
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
              </div>
            </div>
          </div>
          
       <!--------------->
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">

<button type="button" class="btn btn-light mr-2 btn-icon-text" v-on:click="allSeller()"  >
  <i class="mdi mdi-view-list btn-icon-prepend"></i>
  Afficher la liste des clients
</button>
  
<button type="button" class="btn btn-success mr-2 btn-icon-text" v-on:click="ajouterClient()"  >
  <i class="mdi mdi-plus-circle btn-icon-prepend"></i>
  Ajouter un client
  </button>


                    
                     </div>
                     <p v-if="countSearch" class="card-title text-center m-2"> <span  class="text-danger">{{ countSearch }}</span>  resultat(s)</p>
           
                                                     
                     <div class="">
                        
               <p 
                v-if="isLoading"
                class="text-primary justify-content-center  flex-row d-flex font-weight-bold"
              >
                  <pulse-loader
                      class="ml-2"
                      :loading="true"
                      :color="color"
                      :size="size"
                    ></pulse-loader>
              </p>
            </div>
                    
                 
                 
                                                     
                   
                                    <!-- <p v-if="count >=0" class="card-title text-center m-2"> <span v-show="!isLoading" class="text-danger">{{ count }}</span>
                                                              <loading v-if="isLoading" :active="true" :width="10" :height="10" color="black"></loading> resultat(s)</p>
 -->
  <div class="row m-3">
    
                    <table class="table table-hover table-stripped">
  
  <tbody>
    <tr class="bg-white text-black" style="height : 50px;">
      <th scope="row" >Nom Prénom</th>
      <td class="text-center font-weight-bold">Adresse</td>
      <td class="text-center font-weight-bold">Ville</td>
      <td class="text-center font-weight-bold"> Mobile </td>
      <td class="text-center font-weight-bold"> Email </td>
      <td class="text-center font-weight-bold"> Actions </td>
    </tr>
      <tr class="odd text-center" v-if="!countSearch && !error" >
                           <td valign = "top" colspan="7" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
                             No data available in table
                           </td>
                        </tr>
    <tr class="odd text-center" v-if="error" >
                           <td valign = "top" colspan="8" class="dataTables_empty text-center text-danger bg-light font-weight-bold">
                             {{error}}
                           </td>
                        </tr>
    
     <tr v-show="countSearch && !error" class="text-center " v-for="(item, index) in resultQuery" :key="item.id" v-bind:index="index" >
      <td class="text-left py-3 text-uppercase"  scope="row"> {{item.nom}}   </td>
      <td class=""> {{item.adresse}}  </td>
      <td class=""> {{item.ville.city}}  </td>
      <td class=""> {{item.mobile}}  </td>
      <td class="" v-if="item.mail_reception"> {{item.mail_reception}}  </td>
      <td class="text-center" v-else> -----  </td>


      <td class="text-center">
              <a @click="openModel(item)" title="details" class="px-1" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></a>
              <a  @click="edit(item)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success " ></i></a>
               </td>
    </tr>

      
  </tbody>
</table>
     </div>

     <div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent w-100 modal-dialog-centered modal-lg" style=" overflow-y: initial !important">
         <div class="modal-content bg-light " style="">
            <div class="modal-header bg-light">
                  <h5
                              class="modal-title w-100 text-center text-secondary"
                            >
                              Informations client 
                            </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="close()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold"></i>
                            </button>
                     </div>
         <div class=" bg-light p-0">
         
        <div class="modal-body row  p-0 m-0 w-100 container d-flex justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="card user-card-full bg-light">
                    <div class="row m-l-0 m-r-0">
                        <div class="col-sm-4 bg-c-lite-green user-profile bg-light p-0">
                            <div class="card-block text-center bg-light h-100 p-0">
                              
                                <div class="m-b-25 h-75 w-100" style="background-color: transparent !important;"> <img src="images/faces/customer.png" class="img-radius" alt="User-Profile-Image"> </div>
                                <h6 class="f-w-600 text-uppercase">{{item.nom}}</h6>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="card-block">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 text-dark">ref</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h6 class="text-muted f-w-400" v-if="!item.ref">*****</h6>
                                        <h6 class="text-muted f-w-400" v-else>{{item.ref}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 text-dark">adresse</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h6 class="text-muted f-w-400">{{item.adresse}} </h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 text-dark">ville</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h6 class="text-muted f-w-400"> {{item.ville.city}}</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 text-dark">mobile</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h6 class="text-muted f-w-400"> {{item.mobile}} </h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600 text-dark">mail de reception</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <h6 class="text-muted f-w-400" v-if="!item.mail_reception">*****</h6>
                                        <h6 class="text-muted f-w-400" v-else> {{item.mail_reception}} </h6>
                                    </div>
                                </div>
                               <!-- <h6 class="m-b-20 m-t-40 p-b-5 b-b-default f-w-600 text-info">Total des achats</h6> -->
                               <!--  <div class="row">
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Recent</p>
                                        <h6 class="text-muted f-w-400">Sam Disuja</h6>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="m-b-10 f-w-600">Most Viewed</p>
                                        <h6 class="text-muted f-w-400">Dinoter husainm</h6>
                                    </div>
                                </div> -->
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      
</div>
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>


                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
//import axios from 'axios';
import Swal from "sweetalert2";
import moment from "moment";
 import PulseLoader from "vue-spinner/src/PulseLoader.vue";

//import Loading from 'vue3-loading-overlay';

export default {
  components: {
    Header,
    PulseLoader,
    Footer,
    // Loading
  },
  data() {
    return {
      chauffeurs: null,
      users: [],
      clients: null,
      count: "",
      search: "",
      color:'#5cb85c',
      size:'8px',
      id: "test",
      detail: false,
      isDisabled: false,
      selected: null,
      t: null,
      myModel: false,
      actionButton: "Insert",
      dynamicTitle: "Add Data",
      isLoading: false,
      searchQuery:'',
      all:false,
      item:{},
      error:','
    };
  },
  mounted() {
    this.auth = this.$store.getters.get_user;
    //this.getClient();
    console.log("auth", this.auth);
    console.log("auth id", this.auth["userable_id"]);
  },
computed:{
    resultQuery(){
      if(this.searchQuery){
      return this.clients.filter((item)=>{
       return this.searchQuery.toLowerCase().split(' ') .every(() =>  item.nom.toLowerCase().includes(this.searchQuery)   ||  item.ville.city.toLowerCase().includes(this.searchQuery) || item.mobile.toLowerCase().includes(this.searchQuery) || item.adresse.toLowerCase().includes(this.searchQuery) )
      
      })}
      else  
      {
        if(this.all) return this.clients;
        else return []
      }
      
    },
    countSearch(){
      if(this.resultQuery) return this.resultQuery.length;
      return 0;
    },

},

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY hh:mm");
      }
    },
    openModel(item) {
      this.item = item;
      this.actionButton = "Insert";
      this.dynamicTitle = "Add Data";
      this.myModel = true;
    },
    ajouterClient() {
      console.log("clicked!");
      this.$router.push("/addcustomer");
    },
    allSeller(){
      this.searchQuery="";
      this.all =true;
      this.getClient();

    },
     close() {
      this.myModel = false;
      this.item = {};
    },

    searchclient() {
      /*this.t.search=this.search,
      this.t.id=this.auth["userable_id"],
      var data={
        search=this.search,
        id=this.auth["userable_id"]
      }*/
      this.$store
        .dispatch("getItem", {
          //data to be sended
          nameRoute:
            "/api/searchC/" + this.search + "/" + this.auth["userable_id"],
        })
        .then((res) => {
          this.clients = res.data;
          this.count = res.data.length;
        });
    },
    getClient() {
      this.isLoading = true;
      this.$store
        .dispatch("getItem", {
          nameRoute: "/api/getClientsbyvendeur/" + this.auth["userable_id"],
        })
        .then((res) => {
          this.error = "";
          this.isLoading = false;
          console.log(res.data);
          this.clients = res.data;
          this.count = res.data.length;
        })
        .catch((err) => {
          this.clients = {};
          console.log(err);
          this.isLoading = false;

           if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
            else this.error = "Une erreur s'est produite lors du chrgment des clients ! "
   
        });
    },

    archiver(id, index) {
      this.id = id;
      this.$store
        .dispatch("idItem", {
          //data to be sended
          nameRoute: "/api/archiverClient/" + this.id,
        })
        .then((res) => {
          this.getClient();
          this.clients.splice(index, 1);
          // this.count--;
          console.log(res.data);
        });
    },

    desarchiver(id, index) {
      this.selected = id;
      this.id = id;
      this.$store
        .dispatch("idItem", {
          //data to be sended
          nameRoute: "/api/desarchiverClient/" + this.id,
        })
        .then((res) => {
          this.getClient();
          this.isDisabled = false;
          this.clients.splice(index, 1);
          // this.count--;
          this.selected = null;
          console.log(res.data);
        });
    },

    details(json_data) {
      this.$router.push({
        name: "detailsCh",
        params: { data: JSON.stringify(json_data) },
      });
    },
    edit(json_data) {
      this.$router.push({
        name: "editC",
        params: { data: JSON.stringify(json_data) },
      });
      console.log(json_data);
    },

    showMessage(id, index) {
      Swal.fire({
        title: "Attention!",
        text: "Etes vous sur de vouloir archiver ce client?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          this.archiver(id, index);
        }
      });
    },
    parentFire(id) {
      console.log("parentFire works!!", id);
    },

    reset(){
      this.getClient();
      this.all = false;
    }
  },
};
</script>
<style scoped>
tr {
  line-height: 25px;
  min-height: 25px;
  height: 25px;
}

input[type="search"]:focus {
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section {
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: black;
}
.input-search::placeholder {
  color: #6c757d;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: white;
  pointer-events: painted;
}
.btn-search ~ .input-search {
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid #6c757d;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.input-search:focus {
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: 0 0.2rem #6c757d;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.padding {
    padding: 3rem !important
}

.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}



.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px;
    width: 100%;
    height: 100%;

}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}
</style>
import { Script } from "vm";
