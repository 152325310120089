<template>
  <div class="container">
    <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
    <br />
    <br />
    <ring-loader :loading="loading" :color="color1" :size="size"></ring-loader>
    <br />
    <br />
    <bounce-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></bounce-loader>
    <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
    <br />
    <grid-loader :loading="loading" :color="color" :size="size"></grid-loader>
    <br />
    <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
    <br />
    <rise-loader :loading="loading" :color="color" :size="size"></rise-loader>
    <br />
    <beat-loader :loading="loading" :color="color" :size="size"></beat-loader>
    <br />
    <sync-loader :loading="loading" :color="color" :size="size"></sync-loader>
    <br />
    <rotate-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></rotate-loader>
    <br />
    <fade-loader
      :loading="loading"
      :color="color"
      :height="height"
      :width="width"
    ></fade-loader>
    <br />
    <pacman-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></pacman-loader>
    <br />
    <square-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></square-loader>
    <br />
    <scale-loader
      :loading="loading"
      :color="color"
      :height="height"
      :width="width"
    ></scale-loader>
    <br />
    <skew-loader :loading="loading" :color="color" :size="size"></skew-loader>
    <br />
    <moon-loader :loading="loading" :color="color" :size="size"></moon-loader>
    <br />
    <ring-loader :loading="loading" :color="color" :size="size"></ring-loader>
    <br />
    <bounce-loader
      :loading="loading"
      :color="color"
      :size="size"
    ></bounce-loader>
    <br />
    <dot-loader :loading="loading" :color="color" :size="size"></dot-loader>
    <br />
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import BounceLoader from "vue-spinner/src/BounceLoader.vue";

export default {
  data() {
    return {
      color: "#cc181e",
      color1: "#5bc0de",
      size: "45px",
      margin: "2px",
      radius: "2px",
    };
  },
  components: {
    PulseLoader,
    RingLoader,
    BounceLoader,
  },
};
</script>
