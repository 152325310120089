import Vue from "vue";
//import { createRouter, createWebHistory } from 'vue-router'
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import dashboard from "../views/dashboard.vue";
import colis from "../views/colis.vue";
import client from "../views/client.vue";
import addcustomer from "../views/addcustomer.vue";
import editC from "../views/editC.vue";
import settings from "../views/settings.vue";
import addshipment from "../views/addshipment.vue";
import Test from "../components/test.vue";
import store from "../store/index.js";
import Menu from "../components/menu.vue";
import Chart from "../components/chart.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true,
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: Menu,
    meta: {
      secure: true,
    },
  },
  {
    path: "/chart",
    name: "chart",
    component: Chart,
    meta: {
      secure: true,
    },
  },
  {
    path: "/client",
    name: "client",
    component: client,
    meta: {
      secure: true,
    },
  },
  {
    path: "/colis",
    name: "colis",
    component: colis,
    meta: {
      secure: true,
    },
  },
  {
    path: "/addshipment",
    name: "addshipment",
    component: addshipment,
    meta: {
      secure: true,
    },
  },
  {
    path: "/t",
    name: "test",
    component: Test,
    meta: {
      secure: true,
    },
  },
  {
    path: "/addcustomer",
    name: "addcustomer",
    component: addcustomer,
    meta: {
      secure: true,
    },
  },
  {
    path: "/editC",
    name: "editC",
    component: editC,
    meta: {
      secure: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: settings,
    meta: {
      secure: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      secure: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("loggedin*******", store.state.loggedIn);

  if (to.matched.some((record) => record.meta.secure)) {
    // if no token
    if (!store.state.loggedIn) {
      //console.log("no token");
      next({
        path: "/",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (!store.state.loggedIn) {
      next();
    } else {
      //console.log("no token");
      next({
        path: "/dashboard",
      });
    }
  } else {
    next();
  }

  /*const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
    
  });
  router.beforeEach((to, from, next) => {
    console.log(to);
    console.log("permissions",store.state.permissions)
    
    //console.log(role.type_user);
      if(to.matched.some(record => record.meta.secure)){
      // token verification 
     // if(localStorage.getItem('token')== null){
      if(!store.state.loggedIn ){
  
        next({
          path : '/'
        })
      }
      else{
       // if(role.type_user == "admin") 
        next();
      
  
      }
  
    }
    else if (to.matched.some(record => record.meta.guest)){
     // if(localStorage.getItem("token")==null){
      if(!store.state.loggedIn){
  
        next();
      }
      else{
        console.log("no token");
        next({
          path:'/dashboard'
        });
      }
    }
    else{
      next();
    }*/
});

export default router;
